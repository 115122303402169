@import '/src/scss/variables';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $yellow;
  padding: 32px;
  gap: 24px;
  width: 320px;
  border-radius: 8px;

  .title {
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  .text {
    font-size: 26px;
    text-align: center;
  }

  input {
    width: 100%;
    height: 36px;
    padding-left: 10px;
    border: none;
    border-radius: 4px;
  }

  button {
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    color: $white;
    background: $blue;
    border: none;
    height: 42px;
    padding: 8px 48px;
    border-radius: 21px;
    cursor: pointer;
  }

  .message {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    text-align: center;
  }
}

@media (max-width: $screen-size-s) {
  .card {
    margin-left: 16px;
    width: calc(100% - 32px);
    max-width: 320px;
  }
}
