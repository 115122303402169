@import '/src/scss/variables';

.banner {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-image: url('/../public/happy-laptop-people.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  p {
    max-width: 1024px;
    color: $white;
    flex-grow: 1;
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    padding: 8px;
  }
}

.subtitle {
  color: $white;
  max-width: calc(100% - 32px);
  font-size: 56px;
  text-align: center;
}

.download {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: $screen-size-s) {
  .banner {
    margin-top: 76px;
    padding: 32px 0;

    p {
      font-size: 18px;
    }
  }

  .subtitle {
    font-size: 42px;
  }
}
