@import '/src/scss/variables';

.card {
  display: flex;
  flex-direction: column;
  background: $white;
  gap: 16px;
  width: 320px;
  border-radius: 8px;
  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  h3 {
    font-size: 20px;
    color: $black;
  }

  .img_container {
    position: relative;
    width: 100%;
    height: 320px;
    border-radius: 16px;
    overflow: hidden;
  }

  .img {
    background-repeat: no-repeat;
    align-self: center;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0;
  }

  .source {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-size: 14px;
    color: $blue;
    letter-spacing: 1.6px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.2em;
    min-height: 1.2em;

    .site_name {
      text-transform: uppercase;
      text-decoration: underline;
    }
  }

  .title {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.2em;
    min-height: calc(3 * 1.2em);
  }
}

@media (max-width: $screen-size-l) {
  .card {
    width: 240px;

    .img_container {
      height: 240px;
    }
  }
}

@media (max-width: $screen-size-m) {
  .card {
    width: 320px;

    .img_container {
      height: 320px;
    }
  }
}

@media (max-width: $screen-size-s) {
  .card {
    margin-left: 16px;
    width: calc(100% - 32px);

    .title {
      min-height: 0;
    }
  }
}

@media (max-width: $screen-size-xs) {
  .card {
    .img_container {
      height: 180px;
    }
  }
}
