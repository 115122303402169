@import '/src/scss/variables';

.container {
  transition: opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 32px;
  width: 100%;
}

.header {
  width: fit-content;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  letter-spacing: 1px;
  border-bottom: 3px solid $yellow;
  padding: 16px 128px 4px;
  margin: 16px 0 8px;
}

.grid {
  display: grid;
  min-height: 100%;
  gap: 32px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1024px;
}

.button {
  font-family: 'Roboto', sans-serif;
  background: transparent;
  width: fit-content;
  color: $blue;
  border: none;
  font-size: 16px;
  letter-spacing: 1.6px;
  margin-top: 16px;
  padding-bottom: 4px;
  border-bottom: 2px solid $blue;
  cursor: pointer;
}

.events {
  margin-top: 16px;
  background: $blue;
  border-radius: 16px;
  max-width: 1024px;
}

@media (max-width: $screen-size-l) {
  .events {
    width: calc(100% - 64px);
  }
}

@media (max-width: $screen-size-s) {
  .header {
    text-align: center;
    font-size: 24px;
    width: calc(100% - 16px);
    max-width: 360px;
    padding: 4px 0;
  }

  .grid {
    grid-template-columns: 1fr;
    margin: 0;
    gap: 32px;
  }

  .button {
    margin-top: 0;
  }
}
